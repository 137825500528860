<template>
  <div class="pago-rapido-cita">
    <v-row dense>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <th class="text-left">Cliente</th>
                <th>Fecha</th>
                <th>Hora</th>
              </tr>
              <tr>
                <td>
                  {{ pago.cita.cliente.cliente }}
                </td>
                <td>
                  {{ moment(pago.cita.date).format("ll") }}
                </td>
                <td>
                  {{ pago.cita.inicio }}
                </td>
              </tr>
              <tr>
                <th colspan="3">Servicio</th>
              </tr>
              <tr>
                <td>
                  {{ pago.cita.servicio.servicio }} - [IVA
                  {{ pago.cita.servicio.iva }}%]
                </td>
                <td>
                  <v-text-field
                    label="Precio"
                    v-model.number="pago.cita.servicio.valor"
                    outlined
                    rounded
                    dense
                    type="number"
                    class="mt-4"
                  ></v-text-field>
                </td>
                <td>
                  <strong>Precio sin IVA</strong>
                  <br />
                  {{
                    parseFloat(
                      (pago.cita.servicio.valor / ((100 + _iva) / 100)).toFixed(
                        2
                      )
                    ) | currency
                  }}
                </td>
              </tr>
              <tr>
                <th colspan="3">Profesional</th>
              </tr>
              <tr>
                <td colspan="2">
                  {{ pago.cita.profesional.profesional }}
                </td>
                <td>
                  <v-text-field
                    label="Ganancia"
                    v-model.number="pago.cita.profesional.porcentaje"
                    outlined
                    rounded
                    dense
                    type="number"
                    class="mt-4"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th colspan="2">Puntos</th>
                <td>
                  <v-text-field
                    label="Puntos"
                    v-model="_puntos"
                    outlined
                    rounded
                    dense
                    type="number"
                    class="mt-4"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th colspan="3">
                  Forma de pago
                </th>
              </tr>
              <tr>
                <td colspan="3">
                  <v-radio-group v-model="metodo" row>
                    <v-radio label="Efectivo" value="efectivo"></v-radio>
                    <v-radio
                      label="Transferencia"
                      value="transferencia"
                    ></v-radio>
                    <v-radio label="Datafono" value="datafono"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <!-- <v-btn
          dark
          small
          color="black"
          v-if="pago.alert.alert"
          @click="print()"
        >
          <v-icon left>mdi-printer</v-icon>
          Imprimir
        </v-btn> -->
        <!-- <v-btn
          :dark="!loading"
          :color="!loading ? 'black' : 'gray'"
          :loading="loading"
          :disabled="loading"
          v-if="!pago.alert.alert"
          @click="pagar()"
        >
          Pagar
        </v-btn> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "pagoCita",
  props: ["pago", "facturacion"],
  data: () => ({
    metodo: "efectivo",
    loading: false,
    puntos: 0,
  }),
  methods: {
    pagar() {
      this.$emit("setLoading", true);
      const fecha_actual = moment().format("YYYY-MM-DD");
      const porcentaje =
        this.pago.cita.profesional.porcentaje == ""
          ? 0
          : parseFloat(this.pago.cita.profesional.porcentaje);
      const total =
        this.pago.cita.servicio.valor == "" ? 0 : this.pago.cita.servicio.valor;
      const subtotal = parseFloat(
        (total / ((100 + this._iva) / 100)).toFixed(2)
      );
      const iva = parseFloat(
        (((total / ((100 + this._iva) / 100)) * this._iva) / 100).toFixed(2)
      );

      const body = {
        route: "/pago_rapido",
        data: {
          factura: {
            datos_facturacion: this.facturacion.id,
            cita_id: this.pago.cita.id,
            cliente_id: this.pago.cita.cliente.id,
            subtotal:
              this.facturacion.regimen != "simplificado" ? subtotal : total,
            iva: this.facturacion.regimen == "comun" ? iva : 0,
            total: total,
            efectivo: this.metodo == "efectivo" ? total : 0,
            transfer: this.metodo == "transferencia" ? total : 0,
            datafono: this.metodo == "datafono" ? total : 0,
            tipo: "Pago de cita",
            fecha: this.pago.cita.date,
            vencimiento: this.pago.cita.date,
            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            observacion: this.facturacion.notas
          },
          distribucion: {
            profesional: {
              porcentaje: porcentaje,
              profesional_id: this.pago.cita.profesional.id,
              servicio_id: this.pago.cita.servicio.id_activo,
              total:
                this.facturacion.regimen != "simplificado"
                  ? parseFloat(
                      ((subtotal * parseFloat(porcentaje)) / 100).toFixed(2)
                    )
                  : parseFloat(
                      ((total * parseFloat(porcentaje)) / 100).toFixed(2)
                    ),
              order_list: "1.1",
            },
            caja: {
              total:
                this.facturacion.regimen != "simplificado"
                  ? parseFloat(
                      (
                        subtotal -
                        (subtotal * parseFloat(porcentaje)) / 100
                      ).toFixed(2)
                    )
                  : parseFloat(
                      (
                        total -
                        Math.round((total * parseFloat(porcentaje)) / 100)
                      ).toFixed(2)
                    ),
            },
          },
          salida: {
            servicio_id: this.pago.cita.servicio.id_activo,
            subtotal:
              this.facturacion.regimen != "simplificado" ? subtotal : total,
            iva: this.facturacion.regimen == "comun" ? iva : 0,
            total: total,
            order_list: 1,
          },
          puntos: this.puntos,
          time: moment().format("HH:mm:ss"),
        },
      };
      if (!moment(this.pago.cita.date).isAfter(fecha_actual)) {
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              this.pago.alert.alert = true;
              this.factura_id = response.data.data.id;
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.$emit("setElectronico", {
                electronico: response.data.data.electronico,
                factura_id: this.factura_id,
              });
              if (!response.data.data.electronico.authorization)
                this.$emit("setPrint", true);
              else this.$emit("setCancel", false);
              this.$emit("update");
            }
          })
          .catch((error) => {
            this.pago.alert.alert = true;
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => {
            this.$emit("setLoading", false);
          });
      } else {
        this.$emit("setLoading", false);
        let alert = {
          alert: true,
          color: "error",
          text: "No puedes registrar pagos posteriores a la fecha actual",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
    print() {
      const route = this.$router.resolve({ path: "/pos/" + this.factura_id });
      window.open(route.href, "_blank");
    },
    moment(data) {
      return moment(data);
    },
  },
  computed: {
    _iva() {
      let iva = 0;
      if (this.facturacion.regimen == "comun") {
        iva = parseFloat(this.pago.cita.servicio.iva);
      }
      return iva;
    },
    _puntos: {
      get() {
        const total =
          this.pago.cita.servicio.valor == ""
            ? 0
            : this.pago.cita.servicio.valor;
        const subtotal = Math.round(total / ((100 + this._iva) / 100));
        this.puntos = parseInt(subtotal / 1000);
        return parseInt(subtotal / 1000);
      },
      set(val) {
        this.puntos = val == "" ? 0 : parseInt(val);
      },
    },
  },
};
</script>
