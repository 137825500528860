<template>
  <div class="agenda mx-2">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>Agenda de centro</v-card-title>
          <v-card-text>
            <v-row dense>
              <!-- Fecha -->
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Contador -->
              <v-col cols="12" md="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      large
                      text
                      v-on="on"
                      v-bind="attrs"
                      class="mr-2"
                      @click="loadAgenda()"
                    >
                      <v-icon left large class="pr-2">{{ icon }}</v-icon>
                      <span>
                        {{ time }}
                      </span>
                    </v-btn>
                  </template>
                  <span>Actualizar</span>
                </v-tooltip>
              </v-col>
              <!-- Agenda Completa -->
              <v-col cols="12" md="6" sm="6">
                <router-link to="/agendaCompleta" target="_blank">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="black" dark v-on="on" v-bind="attrs">
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <span>Agenda completa</span>
                  </v-tooltip>
                </router-link>
              </v-col>
              <!-- Estados de cita -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" max-width="350px">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <h3 class="text-center">Estados de la cita</h3>
                    </v-col>
                    <v-col
                      v-for="(item, index) in estados"
                      :key="index"
                      align="center"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon :color="item.color">{{ item.icon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ item.txt }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <h3>Citas: {{ filtro.length }}</h3>
              </v-col>
              <!-- Paginado -->
              <v-col cols="12" md="5" sm="6">
                <v-pagination
                  dense
                  v-model="page"
                  :length="Math.ceil(filtro.length / perPage)"
                  :total-visible="6"
                ></v-pagination
              ></v-col>
              <!-- Cantidad citas en lista -->
              <v-col cols="6" md="2" sm="4">
                <v-select
                  label="Citas X pag"
                  :items="itemsPerPage"
                  v-model="perPage"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <!-- Acciones en lote -->
              <v-col cols="12" md="3" sm="6">
                <v-select
                  label="Acciones en lote"
                  :items="lote"
                  v-model="loteAccion"
                  outlined
                  dense
                  clearable
                  @click:clear="($event) => (loteSelected = [])"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="6" v-if="loteAccion">
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      v-on="on"
                      v-bind="attrs"
                      v-show="loteAccion == 'cancelar'"
                      >Aplicar</v-btn
                    >
                  </template>
                  <v-card width="400">
                    <v-card-title>Cancelar citas</v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12">
                          <h3>¿Segur@ de esto?</h3>
                        </v-col>
                        <v-col cols="12" md="12">
                          <p>
                            Debes tener en cuenta que el cambio no podra ser
                            revertido.
                          </p>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-select
                            :items="motivosCancelarLote"
                            v-model="motivoCancelLote"
                            label="Motivo de cancelación"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- <v-btn text color="error">Cancelar</v-btn> -->
                      <v-btn
                        color="black"
                        dark
                        @click="cancelarLote"
                        :disabled="loadingLoteCancel"
                        :loading="loadingLoteCancel"
                        >Aceptar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <!-- <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      v-on="on"
                      v-bind="attrs"
                      v-show="loteAccion == 'reagendar'"
                      >Aplicar</v-btn
                    >
                  </template>
                  <v-card width="400">
                    <v-card-title>Reagendar citas</v-card-title>
                    <v-card-text>
                      <v-date-picker
                        scrollable
                        v-model="newDate"
                        locale="es-co"
                        color="primary"
                        first-day-of-week="1"
                        full-width
                        :min="min"
                      ></v-date-picker>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="black" dark @click="reagendarLote"
                        >Reagendar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu> -->
              </v-col>
              <!-- Lista de citas -->
              <v-col cols="12" md="12">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Estado</th>
                        <th>Hora</th>
                        <th>
                          Cliente
                          <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon color="primary" small
                                  >mdi-filter</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-text-field
                                  outlined
                                  dense
                                  label="Filtrar"
                                  v-model="search_c"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </th>
                        <th>
                          Profesional
                          <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon color="primary" small
                                  >mdi-filter</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-text-field
                                  outlined
                                  dense
                                  label="Filtrar"
                                  v-model="search_p"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </th>
                        <th>
                          Servicio
                          <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon color="primary" small
                                  >mdi-filter</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-text-field
                                  outlined
                                  dense
                                  label="Filtrar"
                                  v-model="search_s"
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loading_t">
                        <th colspan="8" style="height: 4px !important;">
                          <v-progress-linear
                            indeterminate
                            :active="loading_t"
                          ></v-progress-linear>
                        </th>
                      </tr>
                      <tr v-for="(item, index) in pagination" :key="index">
                        <td style="padding: 0px 5px !important;">
                          <v-checkbox v-model="item.lote"></v-checkbox>
                        </td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-on="on" v-bind="attrs">
                                <v-switch
                                  v-model="item.proceso.proceso"
                                  :loading="item.proceso.loading"
                                  dense
                                  @change="proceso(item)"
                                ></v-switch>
                              </span>
                            </template>
                            <span>Procesar cita</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-icon :color="item.estado.color">
                            {{ item.estado.icon }}
                          </v-icon>
                        </td>
                        <td>{{ item.inicio }}</td>
                        <td>
                          <v-list-item v-if="item.cliente.cliente == 'null'">
                            <v-list-item-content>
                              <span class="corte-texto ml-4">
                                {{ item.cliente.cliente }}
                              </span>
                            </v-list-item-content>
                          </v-list-item>
                          <v-hover v-slot="{ hover }">
                            <cliente-agenda
                              :data_cliente="item.cliente"
                              :hover="hover"
                            />
                          </v-hover>
                        </td>
                        <td>
                          <v-list-item>
                            <v-list-item-avatar>
                              <img
                                :src="
                                  $store.state.centro.route +
                                    'profesionales/' +
                                    item.profesional.foto
                                "
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              {{ item.profesional.usuario }}
                              <br />
                              <small
                                class="corte-texto"
                                style="text-align: left; font-weight: bold;"
                              >
                                {{ item.profesional.profesional }}
                              </small>
                            </v-list-item-content>
                          </v-list-item>
                        </td>
                        <td>
                          {{ item.servicio.servicio }}
                        </td>
                        <td>
                          <acciones-agenda
                            :cita="item"
                            v-on:update="loadAgenda()"
                            v-on:pagar="pagar($event, item)"
                            v-on:_clearInterval="_clearInterval()"
                            v-on:_setInterval="_setInterval()"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <pago-rapido
                  :pago="pago"
                  v-on:pagar="pagar($event, {})"
                  v-on:update="loadAgenda()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");

import clienteAgenda from "../../components/ClienteAgenda.vue";
import accionesAgenda from "../../components/AccionesAgenda.vue";
import pagoRapido from "../../components/PagoRapido.vue";
export default {
  components: {
    clienteAgenda,
    accionesAgenda,
    pagoRapido,
  },
  data: () => ({
    // Fecha
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    // Estados de cita
    estados: [
      {
        icon: "mdi-lock-open-alert",
        color: "purple",
        txt: "Cita pendiente",
      },
      {
        icon: "mdi-check-decagram",
        color: "success",
        txt: "Cita confirmada",
      },
      {
        icon: "mdi-check-decagram",
        color: "teal darken-4",
        txt: "Cita recordada",
      },
      {
        icon: "mdi-lock-check",
        color: "primary",
        txt: "Cita en proceso",
      },
    ],
    // Paginado
    page: 1,
    perPage: 25,
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    // Acciones en lote
    lote: [
      {
        text: "Cancelar citas",
        value: "cancelar",
      },
      // {
      //   text: "Reagendar citas",
      //   value: "reagendar",
      // },
    ],
    loteAccion: null,
    loteSelected: [],
    newDate: moment().format("YYYY-MM-YY"),
    min: moment().format("YYYY-MM-YY"),
    motivoCancelLote: null,
    motivosCancelarLote: [
      {
        text: "No asistió",
        value: "asistencia",
      },
      {
        text: "Llamada",
        value: "llamada",
      },
      {
        text: "Profesional",
        value: "profesional",
      },
    ],
    loadingLoteCancel: false,
    // Filtro
    search_c: "",
    search_p: "",
    search_s: "",
    // Lista citas
    loading_t: false,
    agenda: [],
    // Pago
    pago: {
      dialog: false,
      cita: {},
      alert: {},
    },
    // Timer
    expires: 120,
    time: "02:00",
    interval: null,
    icon: "mdi-clock-time-twelve",
  }),
  watch: {
    date(val) {
      this.agenda = [];
      this.page = 1;
      this.loadAgenda();
      return this.date;
    },
  },
  destroyed() {
    console.log("clearInterval");
    clearInterval(this.interval);
  },
  computed: {
    list() {
      return this.agenda.map((agenda) => {
        let estado = parseInt(agenda.estado);
        let render = agenda;
        render.inicio = moment(agenda.inicio, "HH:mm:ss").format("h:mm A");
        render.fin = moment(agenda.fin, "HH:mm:ss").format("h:mm A");
        render["proceso_t"] = moment(agenda.proceso, "HH:mm:ss").format(
          "h:mm A"
        );
        render.proceso = {
          proceso: parseInt(estado) == 3 ? true : false,
          loading: false,
        };
        render["estado"] = {
          color: "purple",
          icon: "mdi-lock-open-alert",
          tipo: estado,
          confirmacion: agenda.confirmacion_state,
          recordatorio: agenda.recordatorio_state,
        };

        render["lote"] = false;

        if (estado == 2 && parseInt(agenda.recordatorio_state) == 0) {
          render["estado"].color = "success";
          render["estado"].icon = "mdi-check-decagram";
        } else if (estado == 2 && parseInt(agenda.recordatorio_state) == 1) {
          render["estado"].color = "teal darken-4";
          render["estado"].icon = "mdi-check-decagram";
        } else if (estado == 3) {
          render["estado"].color = "primary";
          render["estado"].icon = "mdi-lock-check";
        }
        return render;
      });
    },
    filtro() {
      let conditions = [];

      if (this.search_c) conditions.push(this.filtroCliente);

      if (this.search_p) conditions.push(this.filtroProfesional);

      if (this.search_s) conditions.push(this.filtroServicio);

      if (conditions.length > 0) {
        return this.list.filter((ag) => {
          return conditions.every((condition) => {
            return condition(ag);
          });
        });
      }

      return this.list;
    },
    pagination() {
      return this.filtro.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  methods: {
    filtroCliente(item) {
      return (
        item.cliente.cliente
          .toLowerCase()
          .includes(this.search_c.toString().toLowerCase()) ||
        item.cliente.cedula
          .toString()
          .toLowerCase()
          .includes(this.search_c.toString().toLowerCase())
      );
    },
    filtroProfesional(item) {
      return (
        item.profesional.profesional
          .toLowerCase()
          .includes(this.search_p.toString().toLowerCase()) ||
        item.profesional.usuario
          .toString()
          .toLowerCase()
          .includes(this.search_p.toString().toLowerCase())
      );
    },
    filtroServicio(item) {
      return item.servicio.servicio
        .toLowerCase()
        .includes(this.search_s.toString().toLowerCase());
    },
    reagendarLote() {
      if (this.newDate != this.date) {
        if (this.loteSelected.length > 0) {
        } else {
          this.$store.dispatch("setAlert", {
            alert: true,
            color: "error",
            text: "No se selecciono ninguna cita",
          });
        }
      } else {
        this.$store.dispatch("setAlert", {
          alert: true,
          color: "error",
          text: "La fecha para reagendar debe ser posterior",
        });
      }
    },
    cancelarLote() {
      if (this.motivoCancelLote) {
        this.list.forEach((item) => {
          if (item.lote) this.loteSelected.push(item.id);
        });
        if (this.loteSelected.length > 0) {
          this.loadingLoteCancel = true;
          const body = {
            route: "/cancelar_lote",
            data: {
              lote: this.loteSelected,
              motivo: this.motivoCancelLote,
              date: moment().format("YYYY-MM-DD"),
            },
          };
          this.$store
            .dispatch("axios_post", body)
            .then(
              (response) => {
                this.search_c = "";
                this.search_p = "";
                this.search_s = "";
                this.loteAccion = null;
                this.loadAgenda();
                this.$store.dispatch("setAlert", {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                });
              },
              (error) => {
                this.$store.dispatch("setAlert", {
                  alert: true,
                  color: "error",
                  text: error.response.data.data,
                });
              }
            )
            .finally(() => (this.loadingLoteCancel = false));
        } else {
          this.$store.dispatch("setAlert", {
            alert: true,
            color: "error",
            text: "No se selecciono ninguna cita",
          });
        }
      } else {
        this.$store.dispatch("setAlert", {
          alert: true,
          color: "error",
          text: "Debes seleccionar un motivo",
        });
      }
    },
    loadParams() {
      if (this.$route.params.date != undefined) {
        this.date = this.$route.params.date;
      }
    },
    loadAgenda() {
      this.loading_t = true;
      const body = {
        route: `/agenda_centro`,
        params: {
          date: this.date,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.agenda = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    proceso(item) {
      item.proceso.loading = true;
      let estado;
      if (!item.proceso.proceso && item.estado.confirmacion) {
        estado = 2;
      } else if (item.proceso.proceso) {
        estado = 3;
      } else {
        estado = 1;
      }
      const body = {
        route: "/acciones_agenda",
        data: {
          id: item.id,
          type: "proceso",
          state: estado,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.loadAgenda();
          }
        })
        .catch((error) => {
          item.proceso.proceso = false;
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (item.proceso.loading = false));
    },
    pagar(event, cita) {
      if (event) {
        this.pago.alert = {
          alert: false,
          type: "error",
          text: "",
        };
      }
      this.pago.cita = cita;
      this.pago.alert = cita;
      this.pago.dialog = event;
    },
    _setInterval() {
      this._clearInterval();
      let time = moment("2020-01-01 00:02:00", "YYYY-MM-DD hh:mm:ss");
      let minutes;
      let interval_minutes = 0;
      const icons = [
        "mdi-clock-time-one",
        "mdi-clock-time-two",
        "mdi-clock-time-three",
        "mdi-clock-time-four",
        "mdi-clock-time-five",
        "mdi-clock-time-six",
        "mdi-clock-time-seven",
        "mdi-clock-time-eight",
        "mdi-clock-time-nine",
        "mdi-clock-time-ten",
        "mdi-clock-time-eleven",
        "mdi-clock-time-twelve",
      ];
      this.interval = setInterval(() => {
        if (this.expires === 0) {
          this.loadAgenda();
          this.expires = 120;
          this.time = "02:00";
          time = moment("2020-01-01 00:02:00", "YYYY-MM-DD hh:mm:ss");
        } else {
          this.expires -= 1;
          minutes = time.add(-1, "second");
          this.time = minutes.format("mm:ss");
          this.icon = icons[interval_minutes];
          if (interval_minutes < 11) {
            interval_minutes += 1;
          } else {
            interval_minutes = 0;
          }
        }
      }, 1000);
    },
    _clearInterval() {
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
    this._setInterval();
  },
  created() {
    this.loadParams();
    this.loadAgenda();
  },
};
</script>
<style>
a {
  text-decoration: none;
}
</style>
