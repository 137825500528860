var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agenda mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Agenda de centro")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","large":"","text":""},on:{"click":function($event){return _vm.loadAgenda()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"left":"","large":""}},[_vm._v(_vm._s(_vm.icon))]),_c('span',[_vm._v(" "+_vm._s(_vm.time)+" ")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('router-link',{attrs:{"to":"/agendaCompleta","target":"_blank"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}])},[_c('span',[_vm._v("Agenda completa")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-card',{attrs:{"elevation":"0","max-width":"350px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Estados de la cita")])]),_vm._l((_vm.estados),function(item,index){return _c('v-col',{key:index,attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.txt))])])],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('h3',[_vm._v("Citas: "+_vm._s(_vm.filtro.length))])]),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"6"}},[_c('v-pagination',{attrs:{"dense":"","length":Math.ceil(_vm.filtro.length / _vm.perPage),"total-visible":6},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2","sm":"4"}},[_c('v-select',{attrs:{"label":"Citas X pag","items":_vm.itemsPerPage,"outlined":"","dense":""},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-select',{attrs:{"label":"Acciones en lote","items":_vm.lote,"outlined":"","dense":"","clearable":""},on:{"click:clear":function ($event) { return (_vm.loteSelected = []); }},model:{value:(_vm.loteAccion),callback:function ($$v) {_vm.loteAccion=$$v},expression:"loteAccion"}})],1),(_vm.loteAccion)?_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.loteAccion == 'cancelar'),expression:"loteAccion == 'cancelar'"}],attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v("Aplicar")])]}}],null,false,3156906010)},[_c('v-card',{attrs:{"width":"400"}},[_c('v-card-title',[_vm._v("Cancelar citas")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("¿Segur@ de esto?")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',[_vm._v(" Debes tener en cuenta que el cambio no podra ser revertido. ")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.motivosCancelarLote,"label":"Motivo de cancelación"},model:{value:(_vm.motivoCancelLote),callback:function ($$v) {_vm.motivoCancelLote=$$v},expression:"motivoCancelLote"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","dark":"","disabled":_vm.loadingLoteCancel,"loading":_vm.loadingLoteCancel},on:{"click":_vm.cancelarLote}},[_vm._v("Aceptar")])],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th'),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Hora")]),_c('th',[_vm._v(" Cliente "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-filter")])],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filtrar"},model:{value:(_vm.search_c),callback:function ($$v) {_vm.search_c=$$v},expression:"search_c"}})],1)],1)],1)],1),_c('th',[_vm._v(" Profesional "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-filter")])],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filtrar"},model:{value:(_vm.search_p),callback:function ($$v) {_vm.search_p=$$v},expression:"search_p"}})],1)],1)],1)],1),_c('th',[_vm._v(" Servicio "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-filter")])],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filtrar"},model:{value:(_vm.search_s),callback:function ($$v) {_vm.search_s=$$v},expression:"search_s"}})],1)],1)],1)],1),_c('th',[_vm._v("Acciones")])])]),_c('tbody',[(_vm.loading_t)?_c('tr',[_c('th',{staticStyle:{"height":"4px !important"},attrs:{"colspan":"8"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading_t}})],1)]):_vm._e(),_vm._l((_vm.pagination),function(item,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"padding":"0px 5px !important"}},[_c('v-checkbox',{model:{value:(item.lote),callback:function ($$v) {_vm.$set(item, "lote", $$v)},expression:"item.lote"}})],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-switch',{attrs:{"loading":item.proceso.loading,"dense":""},on:{"change":function($event){return _vm.proceso(item)}},model:{value:(item.proceso.proceso),callback:function ($$v) {_vm.$set(item.proceso, "proceso", $$v)},expression:"item.proceso.proceso"}})],1)]}}],null,true)},[_c('span',[_vm._v("Procesar cita")])])],1),_c('td',[_c('v-icon',{attrs:{"color":item.estado.color}},[_vm._v(" "+_vm._s(item.estado.icon)+" ")])],1),_c('td',[_vm._v(_vm._s(item.inicio))]),_c('td',[(item.cliente.cliente == 'null')?_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"corte-texto ml-4"},[_vm._v(" "+_vm._s(item.cliente.cliente)+" ")])])],1):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('cliente-agenda',{attrs:{"data_cliente":item.cliente,"hover":hover}})]}}],null,true)})],1),_c('td',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.$store.state.centro.route +
                                  'profesionales/' +
                                  item.profesional.foto}})]),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.profesional.usuario)+" "),_c('br'),_c('small',{staticClass:"corte-texto",staticStyle:{"text-align":"left","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.profesional.profesional)+" ")])])],1)],1),_c('td',[_vm._v(" "+_vm._s(item.servicio.servicio)+" ")]),_c('td',[_c('acciones-agenda',{attrs:{"cita":item},on:{"update":function($event){return _vm.loadAgenda()},"pagar":function($event){return _vm.pagar($event, item)},"_clearInterval":function($event){return _vm._clearInterval()},"_setInterval":function($event){return _vm._setInterval()}}})],1)])})],2)]},proxy:true}])}),_c('pago-rapido',{attrs:{"pago":_vm.pago},on:{"pagar":function($event){return _vm.pagar($event, {})},"update":function($event){return _vm.loadAgenda()}}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }