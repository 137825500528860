<template>
  <div class="pago-rapido">
    <v-dialog v-model="pago.dialog" persistent max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">Pagar Cita</span>
        </v-card-title>
        <v-card-text>
          <pago-cita
            v-if="!documentoElectronico"
            :pago="pago"
            :facturacion="facturacion"
            v-on:update="update()"
            v-on:setElectronico="setElectronico($event)"
            v-on:setLoading="setLoading($event)"
            v-on:setPrint="setPrint($event)"
            v-on:setCancel="setCancel($event)"
            ref="pagoCita"
          />
          <set-document
            v-if="documentoElectronico"
            :electronico="electronico.electronico"
            :factura_id="electronico.factura_id"
            v-on:setCancel="setCancel($event)"
            v-on:setCancelEnvio="setCancelEnvio()"
            v-on:setPrint="setPrint($event)"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            @click="distribuirPagar()"
            v-if="!pago.alert.alert"
          >
            Distribuir y pagar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="error" text @click="cancel()" v-if="cancel_btn">
            Cerrar
          </v-btn>
          <v-btn
            dark
            small
            color="black"
            v-if="pago.alert.alert && print_post"
            @click="print()"
          >
            <v-icon left>mdi-printer</v-icon>
            Imprimir
          </v-btn>
          <v-btn
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            v-if="!pago.alert.alert"
            @click="pagar()"
          >
            Pagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import pagoCita from "./pagoRapido/pagoCita.vue";
import setDocument from "./SetDocument.vue";
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["pago"],
  name: "pagoRapido",
  components: {
    pagoCita,
    setDocument,
  },
  data: () => ({
    metodo: "efectivo",
    facturacion: {},
    documentoElectronico: false,
    electronico: null,
    loading: false,
    print_post: false,
    cancel_btn: true,
  }),
  methods: {
    loadFacturacion() {
      const body = {
        route: "/facturacion_data",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.facturacion = response.data.data;
        }
      });
    },
    setElectronico(input) {
      this.documentoElectronico = input.electronico.authorization;
      this.electronico = input;
    },
    update() {
      this.$emit("update");
    },
    cancel() {
      this.$emit("pagar", false);
    },
    setCancel(input) {
      this.cancel_btn = input
    },
    setCancelEnvio(){
      this.cancel()
    },
    pagar() {
      this.$refs.pagoCita.pagar();
    },
    setLoading(input) {
      this.loading = input;
    },
    print() {
      this.$refs.pagoCita.print();
    },
    setPrint(input){
      this.print_post = input
    },
    distribuirPagar() {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      this.pago.cita.profesional.profesional = removeAccents(
        this.pago.cita.profesional.profesional
      );
      this.pago.cita.cliente.cliente = removeAccents(
        this.pago.cita.cliente.cliente
      );
      this.pago.cita.servicio.servicio = removeAccents(
        this.pago.cita.servicio.servicio
      );
      let cita = this.pago.cita
      delete cita.notas
      console.log(cita);
      const params = btoa(JSON.stringify(cita));
      this.$router.push("/pagarCita/" + params);
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadFacturacion();
  },
};
</script>
