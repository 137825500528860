<template>
  <div class="acciones-agenda">
    <v-speed-dial
      open-on-hover
      direction="left"
      transition="slide-x-reverse-transition"
      class="d-none d-sm-flex"
    >
      <template v-slot:activator>
        <v-btn icon small :loading="loading">
          <v-icon color="blue">
            mdi-diamond-stone
          </v-icon>
        </v-btn>
      </template>

      <!-- Pagar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="green"
            v-on="on"
            v-bind="attrs"
            v-if="cita.estado.tipo === 3"
            @click="pagar()"
          >
            <v-icon>mdi-account-cash</v-icon>
          </v-btn>
        </template>
        <span>Pagar cita</span>
      </v-tooltip>
      <!--  -->

      <!-- Cuadro -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            :color="cita.cuadro > 0 ? 'purple darken-4' : 'purple lighten-1'"
            v-on="on"
            v-bind="attrs"
            @click="
              openDialogCuadro = true;
              loadCuadroClinico();
            "
          >
            <v-icon>mdi-folder-account</v-icon>
          </v-btn>
        </template>
        <span>Cuadro clinico / Cotización</span>
      </v-tooltip>
      <!--  -->

      <!-- Notas -->
      <v-tooltip top max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="pink lighten-1"
            x-small
            v-on="on"
            v-bind="attrs"
            @click="openNotas"
          >
            <v-icon>mdi-message</v-icon>
          </v-btn>
        </template>
        <span v-if="cita.notas != null && cita.notas != ''">
          {{ cita.notas }}
        </span>
        <span v-else>Notas</span>
      </v-tooltip>
      <!--  -->

      <!-- Confirmar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="teal"
            v-on="on"
            v-bind="attrs"
            @click="acciones('confirmar')"
            v-if="cita.estado.tipo == 1"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Confirmar</span>
      </v-tooltip>
      <!--  -->

      <!-- Recordatorio -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="teal darken-4"
            v-on="on"
            v-bind="attrs"
            v-if="cita.estado.tipo == 2 && cita.estado.recordatorio == 0"
            @click="acciones('recordatorio')"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </template>
        <span>Recordatorio</span>
      </v-tooltip>
      <!--  -->

      <!-- Reagendar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="reagendar"
            v-if="cita.estado.tipo != 3"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        <span>Reagendar</span>
      </v-tooltip>
      <!--  -->

      <!-- Proceso tecnico -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="blue darken-4"
            v-on="on"
            v-bind="attrs"
            @click="openDialogP = true"
            v-if="cita.estado.tipo != 3"
          >
            <v-icon>mdi-cog-transfer</v-icon>
          </v-btn>
        </template>
        <span>Proceso tecnico</span>
      </v-tooltip>
      <!--  -->

      <!-- Notificar Wps -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="notify"
            v-if="cita.estado.tipo != 3"
          >
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </template>
        <span>Notificar al WhatsApp</span>
      </v-tooltip>
      <!--  -->

      <!-- Cancelar cita -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="black"
            v-on="on"
            v-bind="attrs"
            @click="openDialogC = true"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>Cancelar</span>
      </v-tooltip>
      <!--  -->
    </v-speed-dial>

    <v-btn-toggle dense class="d-flex d-sm-none">
      <!-- Cancelar cita -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            v-on="on"
            v-bind="attrs"
            @click="openDialogC = true"
          >
            <v-icon color="black">mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>Cancelar</span>
      </v-tooltip>
      <!--  -->

      <!-- Notificar Wps -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            @click="notify"
            v-show="cita.estado.tipo != 3"
          >
            <v-icon color="success">mdi-phone</v-icon>
          </v-btn>
        </template>
        <span>Notificar al WhatsApp</span>
      </v-tooltip>
      <!--  -->

      <!-- Proceso tecnico -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            @click="openDialogP = true"
            v-show="cita.estado.tipo != 3"
          >
            <v-icon color="blue darken-4">mdi-cog-transfer</v-icon>
          </v-btn>
        </template>
        <span>Proceso tecnico</span>
      </v-tooltip>
      <!--  -->

      <!-- Reagendar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            @click="reagendar"
            v-show="cita.estado.tipo != 3"
          >
            <v-icon color="primary">mdi-calendar</v-icon>
          </v-btn>
        </template>
        <span>Reagendar</span>
      </v-tooltip>
      <!--  -->

      <!-- Recordatorio -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            v-show="cita.estado.tipo == 2 && cita.estado.recordatorio == 0"
            @click="acciones('recordatorio')"
            :loading="loading"
          >
            <v-icon color="teal darken-4">mdi-check-all</v-icon>
          </v-btn>
        </template>
        <span>Recordatorio</span>
      </v-tooltip>
      <!--  -->

      <!-- Confirmar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            @click="acciones('confirmar')"
            v-show="cita.estado.tipo == 1"
            :loading="loading"
          >
            <v-icon color="teal">mdi-check</v-icon>
          </v-btn>
        </template>
        <span>Confirmar</span>
      </v-tooltip>
      <!--  -->

      <!-- Notas -->
      <v-tooltip top max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon small v-on="on" v-bind="attrs" @click="openNotas">
            <v-icon color="pink lighten-1">mdi-message</v-icon>
          </v-btn>
        </template>
        <span v-if="cita.notas != null && cita.notas != ''">
          {{ cita.notas }}
        </span>
        <span v-else>Notas</span>
      </v-tooltip>
      <!--  -->

      <!-- Cuadro -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            @click="
              openDialogCuadro = true;
              loadCuadroClinico();
            "
          >
            <v-icon
              :color="cita.cuadro > 0 ? 'purple darken-4' : 'purple lighten-1'"
              >mdi-folder-account</v-icon
            >
          </v-btn>
        </template>
        <span>Cuadro clinico / Cotización</span>
      </v-tooltip>
      <!--  -->

      <!-- Pagar -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-on="on"
            v-bind="attrs"
            v-show="cita.estado.tipo === 3"
            @click="pagar()"
          >
            <v-icon color="green">mdi-account-cash</v-icon>
          </v-btn>
        </template>
        <span>Pagar cita</span>
      </v-tooltip>
      <!--  -->
    </v-btn-toggle>

    <!-- Cancelar cita -->
    <v-dialog v-model="openDialogC" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          ¿Segur@ de esto?
        </v-card-title>
        <v-card-text>
          <p>
            Deseas cancelar un/una <b>{{ cita.servicio.servicio }}</b
            >, perteneciente a <b>{{ cita.cliente.cliente }}</b
            >, con el/la profesional <b>{{ cita.profesional.profesional }}</b>
            <br />
            <strong
              >Debes tener en cuenta que el cambio no podra ser
              revertido.</strong
            >
          </p>
          <v-select
            :items="cancel"
            v-model="motivo_cancel"
            label="Motivo de cancelación"
            return-object
          ></v-select>
          <v-textarea
            rows="3"
            no-resize
            label="Motivo"
            v-model="motivo_cancel.value"
            v-if="motivo_cancel && motivo_cancel.text == 'Otro'"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="openDialogC = false">
            Cancelar
          </v-btn>
          <v-btn
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="acciones('cancelar')"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->

    <!-- Proceso tecnico -->
    <v-dialog v-model="openDialogP" persistent max-width="500" scrollable>
      <v-card>
        <v-card-title class="headline">
          Proceso tecnico
        </v-card-title>
        <v-card-text>
          <p>
            <b>{{ cita.servicio.servicio }}</b
            >, perteneciente a
            <br />
            <b>{{ cita.cliente.cliente }}</b
            >, con el/la profesional
            <br />
            <b>{{ cita.profesional.profesional }}</b>
            <br />
            <strong
              >Debes tener en cuenta que esta accion solo te permite liberar de
              atras a adelante
            </strong>
          </p>
          <v-row dense>
            <v-col v-if="loading_proceso">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col
              v-else
              v-for="(item, index) in iteracion(
                cita.inicio,
                cita.fin,
                cita.proceso_t
              )"
              :key="index"
              cols="12"
              md="3"
              sm="3"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    dark
                    color="purple lighten-2"
                    width="100px"
                    v-if="item.estado == 0"
                  >
                    {{ item.hora }}</v-btn
                  >
                </template>
                <span>Espacio ocupado</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    width="100px"
                    dark
                    color="purple"
                    v-if="item.estado == 2"
                    @click="liberar(item.db)"
                  >
                    {{ item.hora }}</v-btn
                  >
                </template>
                <span>Espacio para liberar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    width="100px"
                    outlined
                    color="primary"
                    v-if="item.estado == 1"
                    @click="ocupar(item.hora)"
                  >
                    {{ item.hora }}</v-btn
                  >
                </template>
                <span>Espacio liberado</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="openDialogP = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->

    <!-- Notas -->
    <v-dialog v-model="openDialogN" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Notas de la cita
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <p>
                <strong>Cliente: </strong> {{ cita.cliente.cliente }}
                <br />
                <strong>Servicio: </strong> {{ cita.servicio.servicio }}
                <br />
                <strong>Profesional: </strong>
                {{ cita.profesional.usuario }}
                (<small>{{ cita.profesional.profesional }}</small
                >)
                <br />
                <strong>Hora: </strong> {{ cita.inicio }}
              </p>
            </v-col>
            <v-col cols="12">
              <v-textarea
                @input="setNota"
                :value="nota"
                auto-grow
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="
              openDialogN = false;
              $emit('_setInterval');
            "
          >
            cerrar
          </v-btn>
          <v-btn
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="acciones('obs')"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->

    <!-- Cuadro -->
    <v-dialog
      v-model="openDialogCuadro"
      persistent
      :max-width="cotizacion ? '900' : '600'"
    >
      <v-card>
        <v-card-title class="headline">
          Cuadro clínico / Cotización
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="!load_cuadro">
            <v-col cols="12" :md="cotizacion ? '6' : '10'" sm="6">
              <v-checkbox
                v-model="cotizacion"
                label="Realizar cotización"
              ></v-checkbox>
              <v-card elevation="0" outlined>
                <v-card-title
                  ><strong>Cliente: </strong>
                  {{ cita.cliente.cliente }}</v-card-title
                >
                <v-card-subtitle>
                  <strong>Telefono: </strong>
                  {{ cita.cliente.telefono }}
                  <br />
                  <strong>Correo: </strong>
                  {{ cita.cliente.correo }}
                  <br />
                  <strong>Servicio: </strong>
                  {{ cita.servicio.servicio }}
                  <br />
                  <strong>Fecha: </strong>{{ moment(cita.date).format("ll") }}
                  <br />
                  <strong>Hora: </strong>{{ cita.inicio }}
                  <br />
                  <strong>Profesional: </strong>{{ cita.profesional.usuario }}
                  <br />
                  <small>{{ cita.profesional.profesional }}</small>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-if="cotizacion && !load_cuadro" class="mt-2">
            <v-col cols="6" md="6">
              <v-text-field
                v-model="cuadro_values.cotizacion.motivo"
                label="Servicio a cotizar"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Serv / Prod</th>
                      <th>Detalle</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in cuadro_values.cotizacion.proceso"
                      :key="index"
                    >
                      <td>
                        <v-btn small icon @click="deleteItemCotizacion(index)">
                          <v-icon color="error">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </td>
                      <td class="pt-4">
                        <v-text-field
                          v-model="item.item"
                          type="text"
                          outlined
                          dense
                        ></v-text-field>
                      </td>
                      <td class="pt-4">
                        <v-textarea
                          v-model="item.detalle"
                          rows="3"
                          outlined
                          dense
                          style="font-size: 14px;"
                        ></v-textarea>
                      </td>
                      <td class="pt-4">
                        <v-text-field
                          v-model.number="item.valor"
                          type="number"
                          outlined
                          dense
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td>
                        {{ totalizacionCotizacion | currency }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <v-btn text color="primary" @click="addItemCotizacion">
                          <v-icon left>mdi-plus</v-icon>
                          Añadir Serv/Prod
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense v-if="!cotizacion && !load_cuadro" class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.motivo"
                rows="2"
                auto-grow
                label="Motivo de la cita"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.proceso"
                rows="4"
                auto-grow
                label="Proceso"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.resultado"
                rows="4"
                auto-grow
                label="Resultados"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.recomendacion"
                rows="4"
                auto-grow
                label="Recomendaciones"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense v-if="load_cuadro">
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-none d-sm-flex">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="openDialogCuadro = false">
            cerrar
          </v-btn>
          <v-btn
            :dark="!loading"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('no_send')"
            >Guardar</v-btn
          >
          <v-btn
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('send')"
          >
            Guardar y Enviar
          </v-btn>
        </v-card-actions>
        <v-card-actions class="d-flex d-sm-none">
          <v-btn small color="error" text @click="openDialogCuadro = false">
            cerrar
          </v-btn>
          <v-btn
            x-small
            :dark="!loading"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('no_send')"
            >Guardar</v-btn
          >
          <v-btn
            x-small
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('send')"
          >
            Guardar y Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
  </div>
</template>
<script>
import * as moment from "moment";
// import Wps from "./acciones/wps.vue";
// import Reagendar from "./acciones/reagendar.vue";
// import Recordatorio from "./acciones/recordatorio.vue";
// import Confirmar from "./acciones/confirmar.vue";
// import Cuadro from "./acciones/cuadro.vue";
// import Pagar from "./acciones/pagar.vue";
moment.locale("es");
export default {
  name: "accionesAgenda",
  components: {
    // Wps,
    // Reagendar,
    // Recordatorio,
    // Confirmar,
    // Cuadro,
    // Pagar,
  },
  props: ["cita"],
  data: () => ({
    activatorDial: true,
    loading: false,

    // Notas
    openDialogN: false,
    nota: "",

    // Proceso tecnico
    openDialogP: false,
    loading_proceso: false,

    // Cancelar
    openDialogC: false,
    cancel: [
      {
        text: "No asistió",
        value: "asistencia",
      },
      {
        text: "Llamada",
        value: "llamada",
      },
      {
        text: "Profesional",
        value: "profesional",
      },
      {
        text: "Otro",
        value: "",
      },
    ],
    motivo_cancel: null,

    // Cuadro
    openDialogCuadro: false,
    load_cuadro: false,
    cotizacion: false,
    cuadro_values: {
      cotizacion: {
        motivo: null,
        proceso: null,
      },
      cuadro: {
        motivo: null,
        proceso: null,
        resultado: null,
        recomendacion: null,
      },
    },
  }),
  methods: {
    moment(date) {
      return moment(date);
    },
    loadCuadroClinico() {
      this.load_cuadro = true;

      const body = {
        route: "/cuadroClinicoCita",
        params: {
          cita_id: this.cita.id,
        },
      };

      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            for (let cuadro of response.data.data) {
              if (cuadro.tipo == 1) {
                this.cuadro_values.cuadro = {
                  motivo: cuadro.motivo,
                  proceso: JSON.parse(cuadro.proceso).data,
                  resultado: cuadro.resultado,
                  recomendacion: cuadro.recomendacion,
                  id: cuadro.id,
                };
              } else {
                this.cuadro_values.cotizacion = {
                  motivo: cuadro.motivo,
                  proceso: JSON.parse(cuadro.proceso).data,
                  id: cuadro.id,
                };
              }
            }
          }
        })
        .finally(() => (this.load_cuadro = false));
    },
    openNotas() {
      this.nota = this.cita.notas;
      this.openDialogN = true;
      this.$emit("_clearInterval");
    },
    setNota(value) {
      this.nota = value;
    },
    acciones(type) {
      let body = {
        route: "/acciones_agenda",
        data: {
          id: this.cita.id,
          type,
          state: undefined,
          value: "",
        },
      };

      switch (type) {
        case "confirmar":
          body.data.state = 2;
          break;

        case "recordatorio":
          body.data.state = 2;
          break;

        case "obs":
          body.data.value = this.nota;
          break;

        default:
          body.data.state = 5;
          body.data.value = this.motivo_cancel ? this.motivo_cancel.value : "";
          break;
      }

      if (
        (type == "cancelar" && body.data.value == "") ||
        body.data.value == undefined
      ) {
        let alert = {
          alert: true,
          color: "error",
          text: "Debes ingresar un motivo",
        };
        this.$store.dispatch("setAlert", alert);
      } else {
        this.loading = true;
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              this.$emit("update");
              this.dialog_obs = false;
              this.dialog_cancel = false;
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              if (type === "obs") {
                this.$emit("_setInterval");
                this.openDialogN = false;
              }
              this.openDialogC = false;
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },

    iteracion(inicio, fin, proceso) {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(inicio, "h:mm A");
      let end = moment(fin, "h:mm A").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let tecnico = moment(proceso, "h:mm A").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let rangeArray = calculateRange(
        start,
        tecnico,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          db: hora.format("HH:mm:ss"),
          estado: 1,
          disabled: false,
        };
      });

      let duracion = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
        };
      });
      rangeArray.forEach((hora) => {
        duracion.forEach((dh) => {
          if (hora.hora == dh.hora) {
            hora.estado = 0;
            hora.disabled = true;
          }
        });
      });
      if (duracion.length > 1) {
        rangeArray[duracion.length - 1].estado = 2;
        rangeArray[duracion.length - 1].disabled = false;
      }
      return rangeArray;
    },

    liberar(hora) {
      this.loading_proceso = true;
      const body = {
        route: "/proceso_tecnico_liberar",
        data: {
          id: this.cita.id,
          hora,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.cita.fin = hora;
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading_proceso = false));
    },

    ocupar(hora) {
      this.loading_proceso = true;
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(this.cita.fin, "h:mm A");
      let end = moment(hora, "h:mm A");
      let rango = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return { hora: hora.format("HH:mm:ss") };
      });
      const body = {
        route: "/proceso_tecnico_ocupar",
        data: {
          id: this.cita.id,
          hora: end
            .add(+this.$store.state.centro.agenda, "minutes")
            .format("HH:mm:ss"),
          horas: rango,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.cita.fin = end.format("h:mm A");
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading_proceso = false));
    },

    reagendar() {
      this.$router.push({
        name: "tomarCita",
        params: {
          cita: this.cita,
        },
      });
    },

    notify() {
      this.$emit("setActivator", true);
      window.open(
        `https://api.whatsapp.com/send?phone=57${this.cita.cliente.telefono}&text=👋 Hola ${this.cita.cliente.cliente}`,
        "_blank"
      );
    },

    addItemCotizacion() {
      if (this.cuadro_values.cotizacion.proceso == null)
        this.cuadro_values.cotizacion.proceso = [];
      this.cuadro_values.cotizacion.proceso.push({
        item: "",
        detalle: "",
        valor: 0,
      });
    },

    deleteItemCotizacion(index) {
      this.cuadro_values.cotizacion.proceso.splice(index, 1);
    },

    saveCuadro(type) {
      let values = null;

      let request = false;

      let alert = {
        alert: true,
        color: "error",
        text: "",
      };

      if (this.cotizacion) {
        values = this.cuadro_values.cotizacion;
      } else {
        values = this.cuadro_values.cuadro;
      }

      values = Object.assign(
        {
          cita_id: this.cita.id,
          cliente_id: this.cita.cliente.id,
          tipo: this.cotizacion ? 2 : 1,
        },
        values
      );

      if (this.cotizacion) {
        if (values.motivo == null || values.motivo == "") {
          request = true;
          alert.text = "Debes ingresar un motivo";
        } else if (values.proceso == null) {
          request = true;
          alert.text = "Debes ingresar elementos a la cotización";
        }
      } else {
        if (values.motivo == null || values.motivo == "") {
          request = true;
          alert.text = "Debes ingresar un motivo";
        } else if (values.proceso == null) {
          request = true;
          alert.text = "Debes ingresar el proceso";
        } else if (values.resultado == null || values.resultado == "") {
          request = true;
          alert.text = "Debes ingresar un resultado";
        }
      }

      if (request) {
        this.$store.dispatch("setAlert", alert);
      } else {
        this.loading = true;
        values.proceso = {
          data: values.proceso,
        };
        values.proceso = JSON.stringify(values.proceso);
        let route = "/register_cuadro";

        if (values.id != undefined) route = "/update_cuadro";
        const body = {
          route,
          data: {
            data: values,
            id: values.id == undefined ? null : values.id,
            type,
          },
        };

        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              if (type == "no_send") {
                alert.text = `${response.data.data}`;
              } else {
                alert.text = `${response.data.data} \r\n ${response.data.email_status.data}`;
              }
              alert.color = "success";
              this.$store.dispatch("setAlert", alert);
              this.openDialogCuadro = false;
              this.$emit("update");
            }
          })
          .catch((error) => {
            alert.text = error.response.data.data;
            alert.color = "success";
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },

    pagar() {
      this.$emit("pagar", true);
    },
  },
  // created() {
  //   this.loadCuadroClinico();
  // },
  computed: {
    totalizacionCotizacion() {
      if (this.cuadro_values.cotizacion.proceso == null) return 0;
      else
        return this.cuadro_values.cotizacion.proceso.reduce((index, item) => {
          return index + item.valor;
        }, 0);
    },
  },
};
</script>
