<template>
  <div class="cliente-agenda">
    <v-col cols="12" style="padding: 0">
      <v-menu
        :close-on-content-click="false"
        open-on-hover
        offset-x
        :nudge-top="150"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-avatar>
              <img
                :src="
                  $store.state.centro.route + 'clientes/' + data_cliente.foto
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="corte-texto">
                {{ data_cliente.cliente }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-2">
                {{ data_cliente.cliente }}
              </div>
              <v-list-item-title class="headline mb-1">
                Tel: {{ data_cliente.telefono }}
              </v-list-item-title>
              <v-list-item-subtitle
                style="white-space: normal !important; display: block"
              >
                Cc: {{ data_cliente.cedula }}
                <br />
                Correo: {{ data_cliente.correo }}
                <br />
                Dir: {{ data_cliente.direccion }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="80">
              <img
                :src="
                  $store.state.centro.route + 'clientes/' + data_cliente.foto
                "
              />
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link :to="'/detalle_cliente_historial/' + data_cliente.id">
              <v-btn color="primary" text>
                Ver cliente
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </div>
</template>
<script>
export default {
  name: "clienteAgenda",
  props: ["data_cliente", "hover"],
};
</script>
